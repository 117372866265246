@import-normalize;

html {
  min-height: 100vh;
  font-size: 24px;
  line-height: 150%;
}

body {
  min-height: 100%;
  padding: 2vh 2vw;
  font-family: SystemUI, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #63b7e3 0%, #4191dc 100%) no-repeat;
  background-attachment: fixed;
}

a,
a:visited,
a:active {
  color: #55312d;
  text-decoration: none;
  font-weight: bold;
  transition: transform 0.5s;
  display: inline-block;

  &:hover {
    transform: scale(1.1);
  }
}
