.container {
  max-width: 800px;
  margin: 0 auto;

  h1 {
    text-align: center;
    padding: 5vh 0;

    img {
      display: block;
      width: auto;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      max-height: 90%;
    }
  }
}

footer {
  padding: 5vh 0;
  font-size: 80%;
}
